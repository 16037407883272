<template>
  <div>
    <!-- 面包屑导航 -->
<!--    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">-->
<!--      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>数据质量</el-breadcrumb-item>-->
<!--&lt;!&ndash;      <el-breadcrumb-item>项目</el-breadcrumb-item>&ndash;&gt;-->
<!--      <el-breadcrumb-item>-->
<!--        <span @click="back(-4)" style="font-weight: bold" v-if="this.$route.query.crumb">-->
<!--          {{this.$route.query.crumb[0]}}-->
<!--        </span>-->
<!--        <span @click="back(-2)" style="font-weight: bold">系统</span>-->
<!--      </el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>-->
<!--        <span @click="back(-3)" style="font-weight: bold" v-if="this.$route.query.crumb">-->
<!--          {{this.$route.query.crumb[1]}}-->
<!--        </span>-->
<!--        <span @click="back(-3)" style="font-weight: bold">子系统</span>-->
<!--      </el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>-->
<!--        <span @click="back(-2)" style="font-weight: bold" v-if="this.$route.query.crumb">-->
<!--          {{this.$route.query.crumb[2]}}-->
<!--        </span>-->
<!--        <span @click="back(-1)" style="font-weight: bold">设备</span>-->
<!--      </el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>-->
<!--        <span @click="back(-1)" style="font-weight: bold" v-if="this.$route.query.crumb">-->
<!--          {{this.$route.query.crumb[3]}}-->
<!--        </span>-->
<!--        <span @click="back(-1)" style="font-weight: bold">附属设备</span>-->
<!--      </el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>-->
<!--        <span v-if="this.$route.query.crumb">-->
<!--          {{this.$route.query.crumb[4]}}-->
<!--        </span>-->
<!--        <span>数据项</span>-->
<!--      </el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->

    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据清洗标准</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dataQuality-dataQualitySystem' }">
        {{crumbA}}
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dataQuality-dataQualitySubsystem' }">
        {{crumbB}}
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="crumbB!=crumbC" :to="{ path: '/dataQuality-dataQualityDevice' }">
        {{crumbC}}
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="crumbC!=crumbD" :to="{ path: '/dataQuality-dataQualitySubdevice' }">
        {{crumbD}}
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="crumbD!=crumbE">
        {{crumbE}}
      </el-breadcrumb-item>
    </el-breadcrumb>

    <over-view-of-sub-equipment :table-data="tableData" :total="total" v-if="overViewFlag"></over-view-of-sub-equipment>
      <div style="position: relative;" @click="showPanel('startPointId')">
	  <span class="demonstration" style="font-size: 10px;">搜索：</span>
	  <el-input style="display: inline-block;width: 500px;z-index: 111;" type="text" v-model="selectedOptions" suffix-icon="el-icon-arrow-down" placeholder="请选择设备"></el-input>
	  <el-cascader
		  id="startPointId"
		  :options="options"
		  separator=" | "
		  style="width: 400px;"
		  :props="props"
		  placeholder="请选择附属设备"
		  clearable
		  ref="cascade"
		  @change="handleSearch()"
	  ></el-cascader>
      </div> 
      <!--测试-新增数据项的Test-->
<!--      <el-button @click="addMeasurePoint()" style="margin: 20px 10px " type="primary" icon="el-icon-edit" size="medium">新增数据项的Test</el-button>-->
    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="min-width: 100%"
        @expand-change="expandSelect"
        @selection-change="handleSelectionChange"
        v-loading="dataListLoading">
      <!-- <el-table-column
          type="selection"
          width="40">
      </el-table-column> -->
      <el-table-column type="expand" align="center">
        <template slot-scope="scope">
          <el-table border :data="scope.row.tableData2" style="width: 80%;margin-left: 10%" @row-click="clickRow">
            <el-table-column  prop="dataQualityName" header-align="center" align="center" label="标准名称">
            </el-table-column>
            <el-table-column prop="dataQualityValue" header-align="center" align="center" label="标准要求值">
            </el-table-column>
            <el-table-column prop="dataGovernMethodName" header-align="center" align="center" label="治理方法">
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
          label="序号"
          type="index"
          min-width="5%"
          align="center">
        <template slot-scope="scope">
          <span>{{(page - 1) * size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="name"
          label="数据项"
          header-align="center"
          align="center"
          min-width="5%"
      >
      </el-table-column>

      <el-table-column
          prop="operate"
          label="版本"
          header-align="center"
          align="center"
          width="200">
        <template slot-scope="scope">
          <el-button
              @click="openDqVersionManage(scope.row.id)"
              type="text"
              size="small"
          >版本管理</el-button>
        </template>

      </el-table-column>
      <el-table-column
          prop="operate"
          label="操作"
          header-align="center"
          align="center"
          width="500">
        <template slot-scope="scope">
          <el-button
              @click="uploadDqPlugin(scope.row.id)"
              type="text"
              size="small"
              style="margin-right:20px"
          >新增数据清洗标准</el-button>
          <el-button
              @click="dataGovernPreTest(scope.row.id)"
              type="text"
              size="small"
          >测试</el-button>
        </template>

      </el-table-column>
    </el-table>


    <!-- 分页区域 -->
    <div style="display: flex;justify-content: right">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total">
      </el-pagination>
    </div>
    <showUploadDataQuality v-if="showUploadDataQualityVisible" ref="newShow"></showUploadDataQuality>
    <specifyDataQuality v-if="specifyDataQualityVisible" ref="newSpecifyDataQuality"></specifyDataQuality>
    <dataGovernPreTest v-if="dataGovernPreTestVisible" ref="newDataGovernPreTest"></dataGovernPreTest>
    <dataQualityVersion v-if="dataQualityVersionVisible" ref="newDataQualityVersion"></dataQualityVersion>
    </div>

</template>

<script>
import modifyMeasurePoint from "./modifyMeasurePoint";
import checkDataQualityMeasurePoint from "./checkDataQualityMeasurePoint";
import addDataQuality from "./addDataQuality";
import OverViewOfSubEquipment from "../coalSystemManage/overViewOfSubEquipment";  //测试addDataQuality弹窗
import { isPositiveNum, isTwoDecimal } from '@/utils/validate';
import showUploadDataQuality from './showUploadDataQuality'; //测试弹窗显示上传的html页面
import specifyDataQuality from './specifyDataQuality';//制定数据质量标准
import dataGovernPreTest from '../dataQualityPlugin/dataGovernPreTest';//数据治理流程测试
import dataQualityVersion from '../dataQualityPlugin/dataQualityVersion';//数据质量版本管理
export default {
  name: "dataQualityMeasurePoint",
  components: {
    OverViewOfSubEquipment,
    modifyMeasurePoint,
    checkDataQualityMeasurePoint,
    addDataQuality,  //测试addDataQuality弹窗
    showUploadDataQuality, //测试弹窗显示上传的html页面
    specifyDataQuality,//制定数据质量标准
    dataGovernPreTest,//数据治理流程测试
    dataQualityVersion,//数据质量版本管理
  },
  data() {
    var validateIsPositiveNum = (rule, input, callback) => {
      if (input === null || input === '') {
        callback()
      } else {
        if (!isPositiveNum(input)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
    }
    var validateIsTwoDecimal = (rule, input, callback) => {
      if (!isTwoDecimal(input)) {
        callback(new Error('请输入最多两位小数'))
      } else {
        callback()
      }
    }
    let that = this
    return {
		selectedOptions:"",
      multipleSelection: [],
      modifyMPdialogVisible: false,
      checkMPdialogVisible: false,
      ManyMPdialogVisible: false,
      adddialogVisible: false, //测试addDataQuality弹窗
      showUploadDataQualityVisible: false, //测试弹窗显示上传的html页面
      specifyDataQualityVisible:false,//制定数据质量标准
      dataGovernPreTestVisible:false,//数据治理流程测试
      dataQualityVersionVisible:false,//数据质量版本管理
      inputisExistedMethod: '',
      versionsShow: true,
      options: [],
      isExistedoptions: [{
        value: '1',
        label: '是',
      }, {
        value: '0',
        label: '否',
      }],

      Methodoptions: [],

      // value: [],
      // versionoptions: [{
      //     value: 'v1.0',
      //     label: 'v1.0',
      // }, {
      //     value: 'v2.0',
      //     label: 'v2.0',
      // }, {
      //     value: 'v3.0',
      //     label: 'v3.0',
      // }],
      // versionoptions: [],

      value: [],
      measurepointoptions: [],

      visible: false,
      // tableData: [{
      //     id:'',
      //     name: '电机绕组A相温度',
      //     isExisted: '是',
      //     type: 'float',
      //     uprange: '120.0',
      //     rationalityuprange: '125.0',
      //     unit: '℃',
      //     storage_len: '50',
      //     timedelay: '5s',
      //     timesequence: '10s',
      //     version: [],
      // }, {
      //     id:'',
      //     name: '电机绕组B相温度',
      //     isExisted: '是',
      //     type: 'float',
      //     uprange: '120.0',
      //     rationalityuprange: '125.0',
      //     unit: '℃',
      //     storage_len: '50',
      //     timedelay: '5s',
      //     timesequence: '10s',
      //     version: [],
      // }],

      tableData: [{
        id:'',
        name: '',
        isExisted: '',
        type: '',
        uprange: '',
        rationalityuprange: '',
        unit: '',
        storage_len: '',
        timedelay: '',
        timesequence: '',
        version: '',
        versionoptions: [],
      }],

      ManyMPtable: [{
        id:'',
        name: '',
        isExisted: '',
        isExistedmethod: '',
        type: '',
        typemethod: '',
        uprange: '',
        lowrange: '',
        rationalityuprange: '',
        rationalitylowrange: '',
        rationalitymethod: '',
        storage_len: '',
        storage_lenmethod: '',
        timedelay: '',
        timedelaymethod: '',
        timesequence: '',
        timesequencemethod: '',
        modifyrecord: '',
        modifyreason: '',
      }],
      currentSubEquipmentId: 1,
      page:1,  //当前第几页
      size:10, //当前每页个数
      total:0, //全部数据行数

      dataListLoading: false,

      currOperator: '',
      currOperatorName: '',

      overViewFlag: false,

      crumbA: '',
      crumbB: '',
      crumbC: '',
      crumbD: '',
      crumbE: '',
      sizeFromSet: 10,
      rules: {
        type: [
          {required: true, message: '请输入数据类型', trigger: 'blur'}
        ],
        lowrange: [
          {required: true, message: '请输入下阈值', trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        uprange: [
          {required: true, message: '请输入上阈值', trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        rationalitylowrange: [
          {required: true, message: '请输入数据合理范围下阈值',trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        rationalityuprange: [
          {required: true, message: '请输入数据合理范围上阈值',trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        storage_len: [
          {required: false, message:'请输入数据存储长度', trigger:'blur'},
          { validator: validateIsPositiveNum, trigger: 'blur' }
        ],
        timedelay: [
          {required: false, message: '请输入时延要求',trigger: 'blur'},
          { validator: validateIsPositiveNum, trigger: 'blur' }
        ],
        timesequence: [
          {required: false, message: '请输入时序性要求',trigger: 'blur'}
        ],
        version: [
          {required: true, message: '请输入版本', trigger: 'blur'},
          { pattern: /v[1-9]\d*.\d+|0\.\d*[1-9]\d+/,
            message: '请输入正确版本格式' }
        ],
        modifyrecord: [
          {required: true, message: '请输入修改记录', trigger: 'blur'},
        ],
        modifyreason: [
          {required: true, message: '请输入修改原因', trigger: 'blur'}
        ],
      },
      props: {
        //级联选择器懒加载
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          console.log(level, node);
          if (level == 0) {
            that.getAllCoal((list1) => {
              let arr = list1.map((e) => ({ value: e.id, label: e.coal }));
              resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            });
          }
          if (level == 1) {
            let { value } = node;
            console.log(value);
            that.getSystem(value, (list2) => {
              console.log(list2);
              if (list2.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                }]
                resolve(arr);
              } else {
                let arr = list2.map((e) => ({ value: e.id, label: e.name }));
                resolve(arr);
              }
            });
          }
          if (level == 2) {
            let { value } = node;
            console.log(value);
            that.getSubSystem(value, (list3) => {
              console.log(list3);
              if (list3.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                }]
                resolve(arr);
              } else {
                let arr = list3.map((e) => ({ value: e.id, label: e.name }));
                resolve(arr);
              }
            });
          }
          if (level == 3) {
            let { value } = node;
            console.log(value);
            that.getEquipment(value, (list4) => {
              console.log(list4);
              if (list4.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                }]
                resolve(arr);
              } else {
                let arr = list4.map((e) => ({ value: e.id, label: e.name }));
                resolve(arr);
              }
            });
          }
          if (level == 4) {
            let { value } = node;
            console.log(value);
            that.getSubEquipment(value, (list5) => {
              console.log(list5);
              if (list5.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                  leaf: true,
                }]
                resolve(arr);
              } else {
                let arr = list5.map((e) => ({
                  value: e.id,
                  label: e.name,
                  leaf: true,
                }));
                resolve(arr);
              }
            });
          }
        },
      }
    }
  },
  mounted(){
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;

    if(this.global.sub_equipment_id === 0) {
      this.initData_()
    } else {
      this.initDataWithGlobalVar()
    }


    // if (this.$route.query.hasOwnProperty("dataQualitySubDeviceId")) {
    //   this.handleJump();
    // } else {
    //   this.initData();
    // }
    // this.currentSubEquipmentId = window.sessionStorage.getItem("dataQualitysubdeviceId");
    // if(this.currentSubEquipmentId == null) this.currentSubEquipmentId=1; //如果从菜单栏直接进入就没有上一级传过来的id
    // this.initData();
    // this.updateMeasurePoint();
    // this.checkMeasurePoint();
    // this.getMethodOptions();
  },
  methods: {
    showPanel(val){
       document.getElementById(val).click()
    },
	handleChange() {
      this.dataListLoading = true
      if (this.$refs["cascade"].checkedValue === null ||this.$refs["cascade"].checkedValue.length == 0) {
        this.getRequest(
            "/dataQualityMeasurePoint/getMeasurePointInfoByPage/?page=" +
            this.page +
            "&size=" +
            this.size + "&userId=" + this.currOperator
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;

            this.tableData.forEach(item=>{ //拿到各个数据项的versionoptions
              this.getRequest('/dataQualityMeasurePoint/getVersionOptionsbyMeasurePointId/?MeasurePointId=' + item.mpId).then( resp=> {
                if(resp.data) {
                  this.$set(item, "versionoptions", resp.data)
                }
              });
            })
          }
          this.dataListLoading = false
        });
      } else {
        if (this.$route.query.hasOwnProperty("crumb")) {
          for (let i = 0; i < this.$refs["cascade"].getCheckedNodes()[0].pathNodes.length; i++) {
            this.$route.query.crumb[i] = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[i].label
          }
        }
        this.getRequest(
            "/dataQualityMeasurePoint/getMeasurePointBySubEquipmentId?subEquipmentId=" +
            this.$refs["cascade"].getCheckedNodes()[0].value + "&page=" + this.page + "&size=" + this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;

            this.tableData.forEach(item=>{ //拿到各个数据项的versionoptions
              this.getRequest('/dataQualityMeasurePoint/getVersionOptionsbyMeasurePointId/?MeasurePointId=' + item.mpId).then( resp=> {
                if(resp.data) {
                  this.$set(item, "versionoptions", resp.data)
                }
              });
            })
          }
          this.dataListLoading = false
        });
      }
    },
    // 监听 pagesize 改变的事件
    currentChange(currentPage){
      this.page=currentPage;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("dataQualitySubDeviceId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("dataQualitySubDeviceId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    resetSizePage() {
      this.size = this.sizeFromSet
      this.page = 1
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //批量处理
    updateMany() { //批量修改
      this.ManyMPdialogVisible = true;
      this.ManyMPtable = this.$refs.multipleTable.selection;
      //让modifyreason和modifyrecord变成空
      localStorage.setItem('obj', JSON.stringify(this.tableData)); //暂存原始数据
    },

    cancel() {  //批量修改弹窗的“取消按钮”和“关闭按钮（二者效果同理）”，如果取消则恢复原来的数据
      let Item = JSON.parse(localStorage.getItem('obj'));
      this.tableData = Item;
      this.ManyMPdialogVisible = false;
    },

    saveMany() { //批量修改弹窗的保存按钮
      this.$confirm(`确定进行批量修改操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.ManyMPtable.forEach(item=>{ //映射一下isExistedd
          if(item.isExisted=="是") this.$set(item, "isExisted",1);
          else if(item.isExisted=="否") this.$set(item, "isExisted", 0);
        });
        this.postRequest('/dataQualityMeasurePoint/insertManyDataQuality', this.ManyMPtable).then(resp => {
          if (resp.data) {
            this.ManyMPdialogVisible = false;
            this.$refs.multipleTable.clearSelection(); //取消显示选中
            // 映射一下isExisted的显示问题
            this.ManyMPtable.forEach(item=>{
              if(item.isExisted==1) this.$set(item,"isExisted","是");
              else if(item.isExisted==0) this.$set(item,"isExisted","否");
            });
          }
        });
      }).catch(() => {})
    },

    getChildData(arg){ //数据从子组件传到父组件,通过是否点击了取消按钮判断是否需要恢复数据
      let isCancel = arg[0];
      let row = arg[1];
      let Item = JSON.parse(localStorage.getItem('obj'));
      if(isCancel) this.$set(this.tableData,row,Item);
    },


    initData_() {
      if (this.global.coal_id === 0) {
        this.getRequest("/dataList/getAllCoal/").then((resp) => {
          if (resp.data) {
            // 处理全局变量
            this.$changeGlobalVar(this.global.MODE_COAL, resp.data[0].id, resp.data[0].coal)
            // 处理面包屑
            this.crumbA = this.global.coal_name

            this.getRequest(
                "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
            ).then((resp) => {
              if (resp.data) {
                // 处理全局变量
                this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
                // 处理面包屑
                this.crumbB = this.global.system_name

                this.getRequest(
                    "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
                ).then((resp) => {
                  if (resp.data) {
                    // 处理全局变量
                    this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                    // 处理面包屑
                    this.crumbC = this.global.subSystem_name

                    this.getRequest(
                        "/coalManage/getEquipmentInfoBySubSystemId/?subSystemId=" + this.global.subSystem_id
                    ).then((resp) => {
                      if (resp.data) {
                        // 处理全局变量
                        this.$changeGlobalVar(this.global.MODE_EQUIPMENT, resp.data[0].id, resp.data[0].name)
                        // 处理面包屑
                        this.crumbD = this.global.equipment_name
                        this.getRequest(
                            "/coalManage/getSubEquipmentInfoByEquipmentId/?equipmentId=" + this.global.equipment_id
                        ).then((resp) => {
                          if (resp.data) {
                            // 处理全局变量
                            this.$changeGlobalVar(this.global.MODE_SUB_EQUIPMENT, resp.data[0].id, resp.data[0].name)
                            // 处理面包屑
                            this.crumbE = this.global.sub_equipment_name
                            this.initDataWithGlobalVar()
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }else {
        if (this.global.system_id === 0) {
          this.getRequest(
              "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
          ).then((resp) => {
            if (resp.data) {
              // 处理全局变量
              this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
              // 处理面包屑
              this.crumbB = this.global.system_name
            }
          }).then(() => {
            this.getRequest(
                "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
            ).then((resp) => {
              if (resp.data) {
                // 处理全局变量
                this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                // 处理面包屑
                this.crumbC = this.global.subSystem_name
              }
            }).then(() => {
              this.initDataWithGlobalVar()
            })
          })
        } else {
          if (this.global.subSystem_id === 0) {
            this.getRequest(
                "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
            ).then((resp) => {
              if (resp.data) {
                console.log("check:", resp.data)
                // 处理全局变量
                this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                // 处理面包屑
                this.crumbC = this.global.subSystem_name
              }
            })
          } else {
            if (this.global.equipment_id === 0) {
              this.getRequest(
                  "/coalManage/getEquipmentInfoBySubSystemId/?subSystemId=" + this.global.subSystem_id
              ).then((resp) => {
                if (resp.data) {
                  console.log("check:", resp.data)
                  // 处理全局变量
                  this.$changeGlobalVar(this.global.MODE_EQUIPMENT, resp.data[0].id, resp.data[0].name)
                  // 处理面包屑
                  this.crumbD = this.global.equipment_name
                }
              }).then(() => {
                this.initDataWithGlobalVar()
              })
            } else {
              if (this.global.sub_equipment_id === 0) {
                this.getRequest(
                    "/coalManage/getSubEquipmentInfoByEquipmentId/?equipmentId=" + this.global.equipment_id
                ).then((resp) => {
                  if (resp.data) {
                    // 处理全局变量
                    this.$changeGlobalVar(this.global.MODE_SUB_EQUIPMENT, resp.data[0].id, resp.data[0].name)
                    // 处理面包屑
                    this.crumbE = this.global.sub_equipment_name
                  }
                }).then(() => {
                  this.initDataWithGlobalVar()
                })
              }
            }
          }
        }
      }
    },
    initDataWithGlobalVar() {
      console.log("附属设备id为")
      console.log(this.global.sub_equipment_id)
      this.dataListLoading = true
      // 处理面包屑
      this.crumbA = this.global.coal_name
      this.crumbB = this.global.system_name
      this.crumbC = this.global.subSystem_name
      this.crumbD = this.global.equipment_name
      this.crumbE = this.global.sub_equipment_name
      this.dataListLoading = true;
      this.getRequest(
          //"/dataQualityMeasurePoint/getMeasurePointBySubEquipmentId?subEquipmentId=" +
          "/measurePointManage/getMeasurePointInfoBySubEquipmentId?subEquipmentId=" +
          this.global.sub_equipment_id + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
          for(var i=0;i<this.tableData.length;i++){
            this.$set(this.tableData[i],'tableData2',new Array());
          }
        }
        this.dataListLoading = false
      });

    },
    handleSearch() {
      this.resetSizePage()
      if (this.$refs["cascade"].checkedValue === null ||this.$refs["cascade"].checkedValue.length == 0) {
        this.initDataWithGlobalVar()
      } else {
        //处理面包屑
        this.crumbA = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].label
        this.crumbB = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].label
        this.crumbC = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[2].label
        this.crumbD = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[3].label
        this.crumbE = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[4].label

        // 处理全局变量
        this.$changeGlobalVar(this.global.MODE_COAL, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].value, this.crumbA)
        this.$changeGlobalVar(this.global.MODE_SYSTEM, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].value, this.crumbB)
        this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[2].value, this.crumbC)
        this.$changeGlobalVar(this.global.MODE_EQUIPMENT, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[3].value, this.crumbD)
        this.$changeGlobalVar(this.global.MODE_SUB_EQUIPMENT, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[4].value, this.crumbE)
		let arr=[...new Set([this.crumbA,this.crumbB,this.crumbC,this.crumbD])];
		this.selectedOptions=arr.toString().replaceAll(","," | ");  
		this.$forceUpdate();
        this.initDataWithGlobalVar()
      }
    },

    initData(){
      this.dataListLoading = true
      this.getRequest(
          "/dataQualityMeasurePoint/getMeasurePointInfoByPage/?page=" +
          this.page +
          "&size=" +
          this.size + "&userId=" + this.currOperator
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
          this.tableData.forEach(item=>{ //拿到各个数据项的versionoptions
            this.getRequest('/dataQualityMeasurePoint/getVersionOptionsbyMeasurePointId/?MeasurePointId=' + item.mpId).then( resp=> {
              if(resp.data) {
                this.$set(item, "versionoptions", resp.data)
              }
            });
          })
        }
        this.dataListLoading = false
      });
      // this.currentSubEquipmentId = parseInt(this.currentSubEquipmentId);
      // this.getRequest('/dataQualityMeasurePoint/getMeasurePointbySubEquipmentId/?SubEquipmentId=' + this.currentSubEquipmentId +
      //     '&page='+ this.page + '&size='+this.size).then( resp =>{   //通过附属设备id拿到所有的数据项数据质量要求，并且显示最新版本
      //   if (resp.data){
      //     this.tableData = resp.data;
      //     // this.ManyMPtable = resp.data;
      //     this.total = resp.data.size;
      //     this.tableData.forEach(item=>{ //拿到各个数据项的versionoptions
      //       this.getRequest('/dataQualityMeasurePoint/getVersionOptionsbyMeasurePointId/?MeasurePointId=' + item.mpId).then( resp=> {
      //         if(resp.data) {
      //           this.$set(item, "versionoptions", resp.data)
      //         }
      //       });
      //     })
      //   }
      //   // console.log(this.tableData);
      // })
    },
    handleJump() {
      this.dataListLoading = true;
      this.getRequest(
          "/dataQualityMeasurePoint/getMeasurePointBySubEquipmentId?subEquipmentId=" +
          this.$route.query.dataQualitySubDeviceId + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
          this.tableData.forEach(item=>{ //拿到各个数据项的versionoptions
            this.getRequest('/dataQualityMeasurePoint/getVersionOptionsbyMeasurePointId/?MeasurePointId=' + item.mpId).then( resp=> {
              if(resp.data) {
                this.$set(item, "versionoptions", resp.data)
              }
            });
          })
        }
        this.dataListLoading = false
      });
    },
    async getAllCoal(callback) {
      this.getRequest('/dataList/getAllCoal/').then(resp=>{
        if (resp.data){
          callback(resp.data);
          console.log(resp.data)
        }
      });
    },
    async getSystem(coalId, callback) {
      this.getRequest('/coalManage/getSystemInfoByCoalId?coalId=' + coalId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getSubSystem(systemId, callback) {
      this.getRequest('/coalManage/getSubSystemInfoBySystemId?systemId=' + systemId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getEquipment(subSystemId, callback) {
      this.getRequest('/coalManage/getEquipmentInfoBySubSystemId?subSystemId=' + subSystemId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getSubEquipment(equipmentId, callback) {
      this.getRequest('/coalManage/getSubEquipmentInfoByEquipmentId?equipmentId=' + equipmentId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    back(count) {
      history.go(count)
    },

    //上传数据质量插件
    uploadDqPlugin(measurePointId){
      this.specifyDataQualityVisible=true;
      this.$nextTick(()=>{
        this.$refs.newSpecifyDataQuality.init(measurePointId);
      });
    },

    openDqHtml(measurePointId){
      //传入一个时间戳参数，保证每次url都不同，避免iframe缓存导致每次访问的页面都一样
      var ts=new Date().getTime();
      var dqHtmlUrl="http://127.0.0.1/dataGovern/measurePoint"+measurePointId+".html?_"+ts;
      this.showUploadDataQualityVisible=true;
      this.$nextTick(()=>{
        this.$refs.newShow.init(dqHtmlUrl,measurePointId);
      });
    },

    //数据治理流程预先测试
    dataGovernPreTest(measurePointId){
      this.dataGovernPreTestVisible=true;
      this.$nextTick(()=>{
        this.$refs.newDataGovernPreTest.init(measurePointId);
      });
    },

    //打开数据质量版本管理页面
    openDqVersionManage(measurePointId){
      this.dataQualityVersionVisible=true;
      this.$nextTick(()=>{
        this.$refs.newDataQualityVersion.init(measurePointId);
      });
    },
    //查看某个版本数据
    expandSelect(row,expandedRows){
        if(expandedRows.length!=0){
            this.dataListLoading=true;
            this.getRequest(
                "/dataQualityVersion/watchDqInfoByMeasurePointId?measurePointId=" +
                row.id
            ).then((resp) => {
            if (resp) {
                row.tableData2 = resp.data.data;
                this.dataListLoading=false;
            }

            });
        }

    },
    clickRow (row, column, event) {
      console.log(row.id)
      // console.log(column)
      // console.log(event)
    },
  },
}
</script>


<style>
  .el-dialog{
    min-height: 200px;
  }
  #startPointId {
    left: -500px;
    opacity: 0;
  }
</style>
