<template>
    <!--新增对话框-->
    <!--新增按钮所弹出的对话框内容-->
    <el-dialog title="新增数据项数据清洗标准"
               :visible.sync="addMPdialogVisible"
               :close-on-click-modal="false"
               :before-close="cancel"
               width="50%" top="5vh">
        <el-form ref="form" :rules="rules" :model="form" label-width="120px" :hide-required-asterisk="true">
            <el-form-item label="数据项" prop="name"
                            :rules="{required: true, message: '请输入数据项', trigger: 'blur'}">
                <el-input v-model="form.name" ></el-input>
            </el-form-item>

            <!--数据缺失处理模块-->
            <el-form-item label="数据缺失处理" prop="isExisted">
                <!--<el-select v-model="form.isExisted" placeholder="是 / 否" style="width: 100px" @change="isExistedmethodshow">-->
                    <!--<el-option label="是" value="yes" ></el-option>-->
                    <!--<el-option label="否" value="no"></el-option>-->
                <!--</el-select>-->
                <el-switch
                        v-model="isExistedSwitch"
                        active-text="必选"
                        inactive-text="可选"
                        @change="isExistedmethodshow($event)">
                </el-switch>
                <el-select v-model="form.isExistedmethod" placeholder="请选择治理函数" size="small" style="margin-left: 30px">
                    <el-option
                            v-for="item in Methodoptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!--数据类型模块-->
            <el-form-item label="数据类型">
                <el-col :span="5">
                    <el-switch
                            v-model="typeSwitch"
                            active-text="必选"
                            inactive-text="可选">
                    </el-switch>
                </el-col>
               <el-col :span="5">
                   <el-form-item prop="type"
                                 :rules="[{required: typeSwitch, message: '请输入数据类型',trigger: 'blur'}]">
                     <!--                       <el-input v-model="form.type" style="width: 200px"></el-input>-->
                     <el-select v-model="form.type" placeholder="请选择类型" size="small" style="margin-left: 30px">
                       <el-option
                           v-for="item in typeOptions"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                       </el-option>
                     </el-select>
                   </el-form-item>
               </el-col>
                <el-select v-show="show" v-model="form.typemethod"  placeholder="请选择治理函数" size="small" style="margin-left: 70px">
                    <el-option
                            v-for="item in Methodoptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!--阈值范围模块-->
            <el-form-item label="阈值范围" prop="range">
                <el-col :span="5">
                    <el-switch
                            v-model="rangeSwitch"
                            active-text="必选"
                            inactive-text="可选">
                    </el-switch>
                </el-col>
                <el-col :span="3">
                    <el-form-item prop="lowrange"
                                  :rules="[{required:rangeSwitch, message: '请输入上阈值', trigger: 'blur'}]">
                        <!--required要重新设置一个方法，一是下阈值小于上域值，二是上下阈值有其中一个就够了。-->
                    <el-input type="lowrange" v-model.number="form.lowrange" style="width: 100%"></el-input>
                    </el-form-item>
                </el-col>
                <el-col class="line" style="margin-left: 1%;width: auto"> - </el-col>
                <el-col :span="3" style="margin-left: 1%">
                    <el-form-item prop="uprange"
                                  :rules="[{required:rangeSwitch, message: '请输入下阈值', trigger: 'blur'}]">
                        <!--required要重新设置一个方法，一是下阈值小于上域值，二是上下阈值有其中一个就够了。-->
                    <el-input type="uprange" v-model.number="form.uprange" style="width: 100%"></el-input>
                    </el-form-item>
                </el-col>
            </el-form-item>

            <!--数据合理范围模块-->
            <el-form-item label="数据合理范围" prop="rationalityrange">
                <el-col :span="5">
                    <el-switch
                            v-model="rationalityrangeSwitch"
                            active-text="必选"
                            inactive-text="可选">
                    </el-switch>
                </el-col>
                <el-col :span="3">
                    <el-form-item prop="rationalitylowrange"
                                  :rules="[{required: rationalityrangeSwitch, message: '请输入数据合理范围上阈值',trigger: 'blur'}]">
                        <el-input type="rationalitylowrange" v-model.number="form.rationalitylowrange" style="width: 100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col class="line" style="margin-left: 1%;width: auto"> - </el-col>
                <el-col :span="3" style="margin-left: 1%">
                    <el-form-item prop="rationalityuprange"
                                  :rules="[{required: rationalityrangeSwitch, message: '请输入数据合理范围下阈值',trigger: 'blur'}]">
                        <el-input type="rationalityuprange" v-model.number="form.rationalityuprange" style="width: 100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-select v-model="form.rationalitymethod"  placeholder="请选择治理函数" size="small" style="margin-left: 17px">
                    <el-option
                            v-for="item in Methodoptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!--数据存储长度模块-->
            <el-form-item label="数据存储长度">
                <el-col :span="5">
                <el-switch
                        v-model="storage_lenSwitch"
                        active-text="必选"
                        inactive-text="可选">
                </el-switch>
                </el-col>
                <el-col :span="5">
                <el-form-item prop="storage_len"
                                :rules="[{required:storage_lenSwitch, message:'请输入数据存储长度', trigger:'blur'}]">
                <el-input type="storage_len" v-model.number="form.storage_len" style="width: 200px"></el-input>
                </el-form-item>
                </el-col>
                <el-select v-model="form.storage_lenmethod"  placeholder="请选择治理函数" size="small" style="margin-left: 70px">
                    <el-option
                            v-for="item in Methodoptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!--时延要求模块-->
            <el-form-item label="时延要求" >
                <el-col :span="5">
                <el-switch
                        v-model="timedelaySwitch"
                        active-text="必选"
                        inactive-text="可选">
                </el-switch>
                </el-col>
                <el-col :span="5">
                    <el-form-item prop="timedelay"
                                    :rules="[{required: timedelaySwitch, message: '请输入时延要求',trigger: 'blur'}]">
                        <el-input type="timedelay" v-model.number="form.timedelay" style="width: 200px"></el-input>
                    </el-form-item>
                </el-col>
                    <el-select v-model="form.timedelaymethod"  placeholder="请选择治理函数" size="small" style="margin-left: 70px">
                        <el-option
                                v-for="item in Methodoptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
            </el-form-item>

            <!--时序性要求模块-->
            <el-form-item label="时序性要求">
                <el-col :span="5">
                <el-switch
                        v-model="timesequenceSwitch"
                        active-text="必选"
                        inactive-text="可选">
                </el-switch>
                </el-col>
                <el-col :span="5">
                <el-form-item prop="timesequence"
                                :rules="[{required: timesequenceSwitch, message: '请输入时序性要求',trigger: 'blur'}]">
                    <el-input type="timesequence" v-model.number="form.timesequence" style="width: 200px"></el-input>
                </el-form-item>
                </el-col>
                    <el-select v-model="form.timesequencemethod"  placeholder="请选择治理函数" size="small" style="margin-left: 70px">
                        <el-option
                                v-for="item in Methodoptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
            </el-form-item>

            <el-form-item size="large">
                <el-button type="primary" @click="editMeasurePoint()">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </el-form-item>
        </el-form>


    </el-dialog>
</template>

<script>
    export default {
        name: "addDataQuality",
        data() {
            return {
                addMPdialogVisible: false,
                show: true,

                isExistedSwitch: true,
                typeSwitch:true,
                rangeSwitch:true,
                rationalityrangeSwitch: true,
                storage_lenSwitch: true,
                timedelaySwitch: true,
                timesequenceSwitch: true,

                isExistedMethodshow: true,

                istimesequence:true,

                Methodoptions: [],
                typeOptions:[
                  {
                    id: 1,
                    name: "bool"
                  },{
                    id: 2,
                    name: "float"
                  }
                ],

                form: {
                    name: '',
                    isExisted: '',
                    isExistedmethod: '',
                    type: '',
                    typemethod: '',
                    uprange: '',
                    lowrange: '',
                    rationalityuprange: '',
                    rationalitylowrange: '',
                    rationalitymethod: '',
                    storage_len: '',
                    storage_lenmethod: '',
                    timedelay: '',
                    timedelaymethod: '',
                    timesequence: '',
                    timesequencemethod: '',
                    modifyrecord: '',
                    modifyreason: '',
                    version:'',
                },
        //         rules: {
        //             point: [
        //                 {required: true, message: '请输入数据项', trigger: 'blur'}
        //             ],
        //             isExisted: [
        //                 {required: true, message: '请选择数据是否存在', trigger: 'blur'},
        //             ],
        //             uprange: [
        //                 {required:true, message: '请输入上阈值', trigger: 'blur'},
        //             ],
        //             lowrange: [
        //                 {required: true, message: '请输入下阈值', trigger: 'blur'},
        //             ],
        //             rationalityuprange: [
        //                 {required: true, message: '请输入数据合理范围上阈值', trigger: 'blur'},
        //             ],
        //             rationalitylowrange: [
        //                 {required: true, message: '请输入数据合理范围下阈值', trigger: 'blur'},
        //             ],
        //             // storage_len: [
        //             //     {required: !form.storage_lenSwitch, message: '请输入数据存储长度', trigger: 'blur'},
        //             // ],
        //             timedelay: [
        //                 {required: true, message: '请选择时延要求', trigger: 'blur'},
        //             ],
        //             // timesequence: [
        //             //     {required: true, message: '请输入时序性要求',trigger: 'blur'},
        //             //     // {type: 'number', message:'请输入数字',trigger: 'blur'},
        //
        //             // ],
        //         }
            }
        },
        mounted(){
            this.init();
            this.getAllMethod();
        },
        methods: {
            // 展示对话框
            init(row) {
                this.addMPdialogVisible = true;
            },

            cancel(){
                this.form={};
                this.addMPdialogVisible = false;
            },
            // 展示对话框
            editMeasurePoint() { //保存数据项数据
                console.log(this.isExistedSwitch);
                if(this.isExistedSwitch == true) {this.form.isExisted="1";}
                else {this.form.isExisted="0";}
                this.form.version="v1.0";  //新增加的都是第一版

                this.postRequest('/dataQualityMeasurePoint/editMeasurePoint', this.form).then(resp => {
                    if (resp.data) {
                        this.addMPdialogVisible = false;
                    }
                });
            },
            getAllMethod() {
                this.getRequest('/dataQualityMeasurePoint/getAllMethod').then( resp => {
                    if(resp.data) {
                        this.Methodoptions = resp.data;
                        console.log(resp.data);
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
