<template>
  <el-dialog :title="'新增数据清洗标准'" :close-on-click-modal="false" :visible.sync="visible">
    <iframe ref="iframe0" id="iframe0" scrolling="yes" style="border: 0" width="100%" height="350px" src="http://192.168.144.128/dataGovern/type.html">

    </iframe>
    <hr>


    <iframe ref="iframe1" id="iframe1"  scrolling="yes" style="border: 0" width="100%" height="350px" src="http://localhost/dataGovern/length.html">

    </iframe>
    <hr>


    <iframe ref="iframe2" id="iframe2" scrolling="yes" style="border: 0" width="100%" height="350px" src="http://localhost/dataGovern/uprange.html">

    </iframe>
    <hr>


    <iframe ref="iframe3" id="iframe3" scrolling="yes" style="border: 0" width="100%" height="350px" src="http://localhost/dataGovern/lowrange.html">

    </iframe>
    <hr>
    <el-button @click="collectDqInfo">
      提交
    </el-button>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dqHtmlUrl:"",
      dqInfo:"",
      measurePointId:0,
      iframe0:null,
      iframe1:null,
      iframe2:null,
      iframe3:null,
    };
  },
  mounted(){
    window.addEventListener("message",function(e){
      console.log("收到数据质量信息");
      var data=e.data.params.info;
      console.log(data);
    },'*');
  },
  methods: {
    /**
     * 初始化信息
     */
    init(dqHtmlUrl,measurePointId) {
      this.visible = true;
      this.dqHtmlUrl=dqHtmlUrl;
      this.measurePointId=measurePointId;
    },

    /**
     * 向各个iframe发送收集消息的指定
     */
    collectDqInfo(){
      for(var i=0;i<4;i++){
        var frame=document.getElementById('iframe'+i);
        frame.contentWindow.postMessage("submit",'*');
      }
      /* var frame=document.getElementById('iframe0');
      frame.contentWindow.postMessage("submit",'*'); */
    },

    /**
     * 收到iframe传回的信息
     */
    handleMessage(e){
      var data = e.data;
      this.dqInfo=data.params.info;
      console.log("收到数据质量信息");
      console.log(this.dqInfo);

      /* let formData=new FormData();
      formData.append("dataQualityInfo",JSON.stringify(this.dqInfo));
      formData.append("measurePointId",this.measurePointId);
      this.postRequest("/pluginUpload/handleDataQualityInfo",formData).then(response=>{
          if(response&&response.status===200){
              this.visible=false;
          }
        }).then(error=>{
            
        }) */
      console.log("处理数据质量信息完毕");
    },
    
  },
};
</script>