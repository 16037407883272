<template>
  <el-dialog :title=" '制定数据治理标准'" :close-on-click-modal="false" :visible.sync="visible">

    <div class="">
        <el-dropdown :hide-on-click="false" trigger="click">
        <!-- <el-dropdown trigger="click" @command="addDataQuality"> -->
          <span class="el-dropdown-link">
            +添加数据质量标准<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              
              <el-dropdown placement='right-start' class="personalization" trigger="click" @command="addDataQuality">
                <span class="el-dropdown-link">
                  完整性<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in integrity"
                    :key="item.id"
                    :command="item.id">
                    {{ item.dataQualityName }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-dropdown-item>

            <el-dropdown-item>
              <el-dropdown placement='right-start' class="personalization" trigger="click" @command="addDataQuality">
                <span class="el-dropdown-link">
                  合理性<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in rationality"
                    :key="item.id"
                    :command="item.id">
                    {{ item.dataQualityName }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-dropdown-item>

            <el-dropdown-item>
              <el-dropdown placement='right-start' class="personalization" trigger="click" @command="addDataQuality">
                <span class="el-dropdown-link">
                  准确性<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in accuracy"
                    :key="item.id"
                    :command="item.id">
                    {{ item.dataQualityName }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-dropdown-item>

            <el-dropdown-item>
              <el-dropdown placement='right-start' class="personalization" trigger="click" @command="addDataQuality">
                <span class="el-dropdown-link">
                  时效性<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in timeliness"
                    :key="item.id"
                    :command="item.id">
                    {{ item.dataQualityName }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-dropdown-item>
            
          </el-dropdown-menu>
        </el-dropdown>
        <div
          v-for="(iframe,index) in daxian"
          :key="index">
          
          <!-- 数据标准页面  -->
          <iframe
            scrolling="yes" style="border: 0" width="100%" height="100px"
            :src="iframeUrl+'dataGovern/dataQualityIframe'+iframe.id+'.html'"
            :id="'iframe'+iframe.id">

          </iframe>

          <el-form label-width="185px">
            <el-form-item label="选择数据治理函数">
              <!-- 数据治理函数下拉选框 -->
              <el-select :id="'methodSelect'+iframe.id" 
                v-model="governMethodsSelected[index].id" 
                placeholder="请选择数据治理方法"
                size="medium"
                @change="changeGovernMethodSelected($event,index)">
                <el-option v-for="item in governMethods"
                  :key="item.id"
                  :label="item.methodChineseName"
                  :value="item.id">

                </el-option>
              </el-select>
              
              
            </el-form-item>

            <el-form-item>
              <el-input v-for="count in governMethodsSelected[index].paramNumbers"
                v-model="governMethodsSelected[index].params[count-1]"
                type="text"
                :key="index+count"
                :placeholder="'参数'+count"
                size="medium">

              </el-input>
            </el-form-item>
          </el-form>

          
          <el-divider></el-divider>
        </div>
        <el-form label-width="100px">

            <el-form-item>
                <el-button @click="closeDialog()" size="medium">取消</el-button>
                <el-button @click="collectDqInfo()" size="medium">确定</el-button>
            </el-form-item>
            
        </el-form>
        
  </div>
  </el-dialog>
</template>

<script>


export default {
  name:'Simple',
  components:{

  },
  data() {
    return {
      visible: false,
      iframeUrl:this.$store.state.dq_iframe,
      iframes:[],
      integrity:[
        {id:0,dataQualityName:"上阈值"},
        {id:1,dataQualityName:"下阈值"},
      ],
      accuracy:[
        {id:2,dataQualityName:"上限"},
        {id:3,dataQualityName:"下限"},
      ],
      timeliness:[
        {id:4,dataQualityName:"时延要求"},
        {id:5,dataQualityName:"顺序性"},
      ],
      rationality:[

      ],
      iframesSelectedIds:[],
      iframesSelected:[
        
      ],
      iframe:{
        id:0
      },
      dqInfo:[],
      timer:'',
      measurePoindId:0,
      fileList:[],
      governMethods:[],
      governMethodsSelected:[],
      governMethod:{
        id:0,
        methodChineseName:"",
        paramNumbers:0,
        params:[]
      }
    };
  },
  created(){
    this.init(id);
  },
  activated() {
    this.init(id);
  },
  computed:{
    /**
     * 将所选iframe去重
     */
    daxian(){
      const res=new Map();
      return this.iframesSelected.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    }
  },
  mounted(){
    var that=this;
    window.addEventListener("message",function(e){
      var data=e.data.params.info;
      that.dqInfo.push(data);
      
    },'*');
  },
  methods: {
    init(id) {
      this.visible=true;
      this.iframesSelected=[];
      this.iframesSelectedIds=[];
      this.measurePoindId=id;
      this.fileList=[];
      this.governMethodsSelected=[];
      this.dqInfo=[];
      this.getRequest(
          "/dataQualityIframe/getAllDataQualityIframeInfo"
      ).then((resp) => {
        if (resp) {
          this.iframes = resp.data.data;
        }
      });
      //获取完整性标准
      this.getRequest(
          "/dataQualityIframe/getDataQualityIframeInfoByType?type=完整性"
      ).then((resp) => {
        if (resp) {
          this.integrity = resp.data.data;
        }
      });
      //获取合理性标准
      this.getRequest(
          "/dataQualityIframe/getDataQualityIframeInfoByType?type=合理性"
      ).then((resp) => {
        if (resp) {
          this.rationality = resp.data.data;
        }
      });
      //获取准确性标准
      this.getRequest(
          "/dataQualityIframe/getDataQualityIframeInfoByType?type=准确性"
      ).then((resp) => {
        if (resp) {
          this.accuracy = resp.data.data;
        }
      });
      //获取时效性标准
      this.getRequest(
          "/dataQualityIframe/getDataQualityIframeInfoByType?type=时效性"
      ).then((resp) => {
        if (resp) {
          this.timeliness = resp.data.data;
        }
      });
      this.getRequest(
          "/dataGovernMethod/getAllDataGovernInfo"
      ).then((resp) => {
        if (resp) {
          this.governMethods = resp.data.data;
        }
        this.dataListLoading = false
      });
    },

    addDataQuality(command){
      this.iframesSelectedIds.push(command);
      this.iframesSelected.push({id:command});
      if(this.governMethodsSelected[this.daxian.length]==null){
        this.governMethodsSelected.push({id:1,methodName:"defaultMethod",methodChineseName:"不治理",jarFileName:"defaultMethods.jar",paramNumbers:0,params:[]});
      }
      
    },

    changeGovernMethodSelected(event,index){
      for(var i=0;i<this.governMethods.length;i++){
        if(event==this.governMethods[i].id){
          this.governMethodsSelected[index].methodName=this.governMethods[i].methodName;
          this.governMethodsSelected[index].methodChineseName=this.governMethods[i].methodChineseName;
          this.governMethodsSelected[index].jarFileName=this.governMethods[i].jarFileName;
          this.governMethodsSelected[index].paramNumbers=this.governMethods[i].paramNumbers;
          if(this.governMethods[i].cacheNeeded==1){
            this.governMethodsSelected[index].paramNumbers-=1;
          }
        }
      }
      console.log(this.governMethodsSelected);
    },

    /**
     * 向各个iframe发送收集消息的指定
     */
    collectDqInfo(){
      var that=this;
      for(var i=0;i<this.daxian.length;i++){
        var frame=document.getElementById('iframe'+this.daxian[i].id);
        frame.contentWindow.postMessage("submit",'*');
      }
      /* console.log(that.fileList[0]);
      var jarFileName=that.fileList[0].name;
      if(jarFileName.substring(jarFileName.indexOf('.')+1)!="jar"){
        this.$message({
          message:"请上传后缀名为'.jar'格式的文件",
          type:'error'
        });
        return;
      } */
      

      clearTimeout(this.timer);
      this.timer=setTimeout(function(){
        console.log("以下为数据标准信息");
        console.log(that.dqInfo);
        console.log("以下为所选数据治理函数");
        console.log(that.governMethodsSelected);
        console.log("以下为原id数组");
        console.log(that.iframesSelectedIds);
        console.log("以下为去重后的id数组");
        console.log(that.unique(that.iframesSelectedIds));
        console.log("数据项id:"+that.measurePoindId);
        
        var formData=new FormData();
        formData.append("measurePointId",that.measurePoindId);
        formData.append("iframesSelectedIds",that.unique(that.iframesSelectedIds));//所选的数据标准页面id数组
        formData.append("dqInfo",JSON.stringify(that.dqInfo));//填写好的数据标准信息
        formData.append("governMethodsSelected",JSON.stringify(that.governMethodsSelected));//选择好的治理方法信息
        /* let data={
          dqInfo:JSON.stringify(that.dqInfo),
          governMethodsSelected:JSON.stringify(that.governMethodsSelected),
        } */
        that.postRequest("/dataQualityIframe/collectDqInfoToXml",formData).then(response=> {
            that.visible=false;
                
        }).then(error => {
          
        })
      },300)

    },


    unique(arr){
      return Array.from(new Set(arr))
    },

    /**
     * 点击取消按钮
     */
    closeDialog(){
        this.fileList=[];
        this.visible=false;
    },
    beforeDestroy() {
      clearTimeout(this.timer);
    },


  },
};
</script>
<style scoped>
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-input{
    width: 202px;
    margin-right: 10px;
  }
</style>