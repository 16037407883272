<template>
  <!--修改对话框-->
  <!--修改按钮所弹出的对话框内容-->
  <el-dialog title="修改数据项数据清洗标准"
             :visible.sync="modifyMPdialogVisible"
             :close-on-click-modal="false"
             :before-close="cancel"
             width="50%" top="5vh">
    <el-form ref="form" :rules="rules" :model="form" label-width="120px">
      <el-form-item label="数据项">
        <div>{{form.name}}</div>
      </el-form-item>
      <el-form-item label="数据是否存在">
        <el-select v-model="form.isExisted" placeholder="是 / 否" style="width: 100px" @change="methodshow">
          <el-option label="是" value="1" ></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>
        <el-select v-show="show" v-model="form.isExistedmethod" placeholder="请选择治理函数" size="small" style="width: 100px">
          <el-option
              v-for="item in Methodoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据类型" prop="type">
        <el-input v-model="form.type" style="width: 100px"></el-input>
        <el-select v-model="form.typemethod" placeholder="请选择治理函数" size="small" style="width: 100px">
          <el-option
              v-for="item in Methodoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item  label="阈值范围">
        <el-col :span="3">
          <el-form-item prop="lowrange">
            <el-input v-model="form.lowrange" style="width: 100px"></el-input>
          </el-form-item>
        </el-col>
        <el-col class="line" style="margin-left: 1%;width: auto"> -> </el-col>
        <el-col :span="3" style="margin-left: 1%">
          <el-form-item prop="uprange">
            <el-input v-model="form.uprange" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="数据合理范围">
        <el-col :span="3">
          <el-form-item prop="rationalitylowrange">
            <el-input v-model="form.rationalitylowrange" style="width: 100px"></el-input>
          </el-form-item>
        </el-col>
        <el-col class="line" style="margin-left: 1%;width: auto"> -> </el-col>
        <el-col :span="3" style="margin-left: 1%">
          <el-form-item prop="rationalityuprange">
            <el-input v-model="form.rationalityuprange" style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
        <el-select v-model="form.rationalitymethod" placeholder="请选择治理函数" size="small" style="width: 100px">
          <el-option
              v-for="item in Methodoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据存储长度" prop="storage_len">
        <el-input v-model="form.storage_len" style="width: 100px"></el-input>
        <el-select v-model="form.storage_lenmethod" placeholder="请选择治理函数" size="small" style="width: 100px">
          <el-option
              v-for="item in Methodoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时延要求" prop="timedelay">
        <el-input v-model="form.timedelay" style="width: 100px"></el-input>
        <el-select v-model="form.timedelaymethod" placeholder="请选择治理函数" size="small" style="width: 100px">
          <el-option
              v-for="item in Methodoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时序性要求" prop="timesequence">
        <el-input v-model="form.timesequence" style="width: 100px"></el-input>
        <el-select v-model="form.timesequencemethod" placeholder="请选择治理函数" size="small" style="width: 100px">
          <el-option
              v-for="item in Methodoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="版本" prop="version">
        <el-input v-model="form.version"  style="width: 100px" disabled> </el-input>
      </el-form-item>
      <el-form-item label="修改记录" prop="modifyrecord" >
        <el-input v-model="form.modifyrecord" type="textarea" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="修改原因" prop="modifyreason" >
        <el-input v-model="form.modifyreason" type="textarea" style="width: 300px"></el-input>
      </el-form-item>

      <el-form-item size="large">
        <el-button type="primary" @click="editMeasurePoint()">保存</el-button>
        <el-button @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>


  </el-dialog>
</template>

<script>
import { isPositiveNum, isTwoDecimal } from '@/utils/validate'
export default {
  data() {
    var validateIsPositiveNum = (rule, input, callback) => {
      if (input === '' || input === null) {
        callback()
      } else {
        if (!isPositiveNum(input)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
    }
    var validateIsTwoDecimal = (rule, input, callback) => {
      if (!isTwoDecimal(input)) {
        callback(new Error('请输入最多两位小数'))
      } else {
        callback()
      }
    }
    return {
      modifyMPdialogVisible: false,
      show: true,
      total: 100,
      row:1,
      isCancel:false,


      Methodoptions: [],

      form: {
        name: '',
        isExisted: '',
        isExistedmethod: '',
        type: '',
        typemethod: '',
        uprange: '',
        lowrange: '',
        rationalityuprange: '',
        rationalitylowrange: '',
        rationalitymethod: '',
        storage_len: '',
        storage_lenmethod: '',
        timedelay: '',
        timedelaymethod: '',
        timesequence: '',
        timesequencemethod: '',
        modifyrecord: '',
        modifyreason: '',
        version:'',
      },

      rules: {
        type: [
          {required: true, message: '请输入数据类型', trigger: 'blur'}
        ],
        lowrange: [
          {required: true, message: '请输入下阈值', trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        uprange: [
          {required: true, message: '请输入上阈值', trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        rationalitylowrange: [
          {required: true, message: '请输入数据合理范围下阈值',trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        rationalityuprange: [
          {required: true, message: '请输入数据合理范围上阈值',trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        storage_len: [
          {required: false, message:'请输入数据存储长度', trigger:'blur'},
          { validator: validateIsPositiveNum, trigger: 'blur' }
        ],
        timedelay: [
          {required: false, message: '请输入时延要求',trigger: 'blur'},
          { validator: validateIsPositiveNum, trigger: 'blur' }
        ],
        timesequence: [
          {required: false, message: '请输入时序性要求',trigger: 'blur'}
        ],
        version: [
          {required: true, message: '请输入版本', trigger: 'blur'},
          { pattern: /v[1-9]\d*.\d+|0\.\d*[1-9]\d+/,
            message: '请输入正确版本格式' }
        ],
        modifyrecord: [
          {required: true, message: '请输入修改记录', trigger: 'blur'},
        ],
        modifyreason: [
          {required: true, message: '请输入修改原因', trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
    this.getMethodOptions();
    this.init();
  },
  methods: {
    init(data,row) {
      var v = data.version
      var i = parseInt(v.charAt(1)) + 1
      data.version = 'v' + i + ".0"
      this.modifyMPdialogVisible = true;
      this.form = data;
      this.row = row;
      localStorage.setItem('obj', JSON.stringify(data)); //暂存原始数据
    },

    cancel() { //取消保存
      // 取消时把原来存储的值赋给现在的form对象中
      let Item = JSON.parse(localStorage.getItem('obj'));
      this.isCancel=true;
      // this.$emit('cancelclick',this.isCancel,this.row); //把数据传到父组件里
      this.modifyMPdialogVisible = false;
    },
    editMeasurePoint() { //保存数据项数据
      this.isCancel=false;
      if(this.form.isExisted=="是") this.form.isExisted=1;
      else if(this.form.isExisted=="否") this.form.isExisted=0;
      this.$confirm(`确定对[${this.form.name}]进行修改操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postRequest('/dataQualityMeasurePoint/insertMeasurePoint', this.form).then(resp => {
          if (resp.data) {
            //映射一下isExisted的显示问题
            if(this.form.isExisted=="1") this.form.isExisted="是";
            else this.form.isExisted="否";
            this.modifyMPdialogVisible = false;
            this.$emit('refreshDataTable')
          }
        });
      }).catch(() => {})
    },
    methodshow() {
      if (this.form.isExisted == '1') {
        this.show = true;
      } else {
        this.show = false;
      }
    },

    getMethodOptions() {
      this.getRequest('/dataQualityMeasurePoint/getAllMethod').then( resp => {
        if(resp.data) {
          this.Methodoptions = resp.data;
        }
      })
    },

  }
}
</script>

<style scoped>

</style>
