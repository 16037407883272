<template>
  <el-dialog :title="'数据质量版本管理'" :close-on-click-modal="false" :visible.sync="visible">
    <div>
    <el-table :data="dataList" border 
      @expand-change="expandSelect"
      @row-click="clickRow"
      v-loading="dataListLoading"
      style="width: 100%;">
      <el-table-column type="expand" align="center">
        <template slot-scope="props">
          <el-table border :data="props.row.tableData2" style="width: 80%;margin-left: 10%" @row-click="clickRow">
            <el-table-column  prop="dataQualityName" header-align="center" align="center" label="标准名称">
            </el-table-column>
            <el-table-column prop="dataQualityValue" header-align="center" align="center" label="标准要求值">
            </el-table-column>
            <el-table-column prop="dataGovernMethodName" header-align="center" align="center" label="治理方法">
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column  
        prop="versionNumber" 
        header-align="center" 
        align="center" 
        label="数据质量标准版本"
        :formatter="versionNumberFormatter">
      </el-table-column>
      <el-table-column 
        prop="versionSelected" 
        header-align="center" 
        align="center" 
        label="使用中">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.versionSelected == 1" size="small">是</el-tag>
          <el-tag v-else size="small" type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" label="发布时间">
      </el-table-column>
      <el-table-column
          prop="operate"
          label="操作"
          header-align="center"
          align="center"
          width="200">
        <template slot-scope="scope">
          <el-button
              @click="selectVersion(scope.row.measurePointId,scope.row.versionNumber)"
              type="text"
              size="small"
              :disabled="scope.row.versionSelected==1"
          >使用</el-button>
        </template>

      </el-table-column>
      
    </el-table>
    </div>
    
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataList:[],
      measurePointId:0,
      dataListLoading:false,
    };
  },
  mounted(){

  },
  methods: {
    /**
     * 初始化信息
     */
    init(id) {
      this.visible = true;
      this.measurePointId=id;
      this.dataListLoading=true;
      this.getRequest(
          "/dataQualityVersion/getInfoByMeasurePointId?measurePointId=" +
          id
        ).then((resp) => {
          //数据格式
          let temp =[{id: 20, measurePointId: 12, versionNumber: 1, versionSelected: 1, createTime: "2022-08-30 15:26:19"}]
          if (resp) {
            this.dataList=resp.data.data;
            for(var i=0;i<this.dataList.length;i++){
                this.$set(this.dataList[i],'tableData2',new Array());
            }
            
          }
          this.dataListLoading=false;
        });
        
    },
    
    //版本号formatter
    versionNumberFormatter(row,column){
        return("v"+row.versionNumber);
    },

    //查看某个版本数据
    expandSelect(row,expandedRows){
        if(expandedRows.length!=0){
            this.dataListLoading=true;
            this.getRequest(
                "/dataQualityVersion/watchDqInfoByVersion?measurePointId=" +
                row.measurePointId +
                "&versionNumber=" +
                row.versionNumber
            ).then((resp) => {
            if (resp) {
                row.tableData2 = resp.data.data;
                this.dataListLoading=false;
            }
            
            });
        }
        
    },
    clickRow (row, column, event) {
      console.log(row.id)
      // console.log(column)
      // console.log(event)
    },
    //选用版本
    selectVersion(measurePointId,versionNumber){
      this.$confirm(`确定使用该版本数据质量标准?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest(
                "/dataQualityVersion/selectVersion?measurePointId=" +
                measurePointId +
                "&versionNumber=" +
                versionNumber
            ).then((resp) => {
            if (resp) {
                if(resp.data.data=="RunningTask"){
                  this.$message({
                  type: "warning",
                  message: "该数据项所在接入任务正在运行，请先停止接入任务",
                });
                }
                this.init(measurePointId);
            }
            
            });
        }).catch(() => {})
    }
    
  },
};
</script>
<style scoped>

</style>