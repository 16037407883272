<template>
  <div>
    <span style="font-size: 12px">概述：</span>
    <div class="coalblock" v-for="item in options" :key="item.title">
      <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>
      <!-- <br /> -->
      <span style="font-size: 24px;margin-left: 10px;">{{ item.num }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "overViewOfSubEquipment",
  props: ['total'],
  watch: {
    total (newVal) {
      this.options[0].num = this.total + "个";
    }
  },
  data() {
    return {
      options: [{
        title: "数据项总数",
        num: 0
      }]
    }
  },
  mounted() {
    this.options[0].num = this.total + "个";
  }
}
</script>

<style scoped>

</style>
