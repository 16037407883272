<template>
  <!--查看治理函数对话框-->
  <!--查看治理函数按钮所弹出的对话框内容-->
  <el-dialog
      title="查看数据项清洗标准"
      width="100%"
      :close-on-click-modal="true"
      :visible.sync="checkMPdialogVisible"
      v-if="checkMPdialogVisible"
      @close="closeDialog"
  >
      <span slot="footer" class="dialog-footer">
          <!--这里应该还有一个保存的方法没写-->
          <el-button type="primary" @click="checkMPdialogVisible = false">确 定</el-button>
      </span>
    <el-table
        :data="checkMPtable"
        ref="checkMPtableRef"
    >
      <!-- 利用 index 设置小方块的颜色显示  -->
      <el-table-column label="">
        <el-table-column
            prop="name"
            label="数据项"
            header-align="center"
            align="center" width="150">
        </el-table-column>
      </el-table-column>
      <el-table-column
          label="完整性"
          header-align="center"
          align="center">
        <el-table-column
            prop="isExisted"
            label="数据是否存在"
            header-align="center"
            align="center"
            width="80">
        </el-table-column>
        <el-table-column
            prop="isExistedmethod"
            label="数据完整性治理函数"
            header-align="center"
            align="center"
            width="100" >
        </el-table-column>
      </el-table-column>
      <el-table-column
          label="准确性"
          header-align="center"
          align="center">
        <el-table-column
            prop="type"
            label="数据类型"
            header-align="center"
            align="center"
            width="80">
        </el-table-column>
        <el-table-column
            prop="typemethod"
            label="数据类型治理函数"
            header-align="center"
            align="center"
            width="100" >
        </el-table-column>
        <el-table-column
            label="阈值范围"
            header-align="center"
            align="center">
          <el-table-column
              prop="uprange"
              label="上阈值"
              header-align="center"
              align="center"
              width="80">
          </el-table-column>
          <el-table-column
              prop="lowrange"
              label="下阈值"
              header-align="center"
              align="center"
              width="80">
          </el-table-column>
        </el-table-column>
        <el-table-column
            prop="rationality"
            label="数据合理范围"
            header-align="center"
            align="center">
          <el-table-column
              prop="rationalityuprange"
              label="上阈值"
              header-align="center"
              align="center"
              width="80">
          </el-table-column>
          <el-table-column
              prop="rationalitylowrange"
              label="下阈值"
              header-align="center"
              align="center"
              width="80">
          </el-table-column>
        </el-table-column>
        <el-table-column
            prop="rationalitymethod"
            label="数据合理范围治理函数"
            header-align="center"
            align="center"
            width="100" >
        </el-table-column>
        <el-table-column
            prop="storage_len"
            label="数据存储长度"
            header-align="center"
            align="center"
            width="80">
        </el-table-column>
        <el-table-column
            prop="storage_lenmethod"
            label="数据存储长度治理函数"
            header-align="center"
            align="center"
            width="100" >
        </el-table-column>
      </el-table-column>
      <el-table-column
          label="时效性"
          header-align="center"
          align="center">
        <el-table-column
            prop="timedelay"
            label="时延要求"
            header-align="center"
            align="center"
            width="80">
        </el-table-column>
        <el-table-column
            prop="timedelaymethod"
            label="时延要求治理函数"
            header-align="center"
            align="center"
            width="100" >
        </el-table-column>
        <el-table-column
            prop="timesequence"
            label="时序性要求"
            header-align="center"
            align="center"
            width="80">
        </el-table-column>
        <el-table-column
            prop="timesequencemethod"
            label="时序性要求治理函数"
            header-align="center"
            align="center"
            width="100" >
        </el-table-column>
      </el-table-column>
      <el-table-column
          prop="version"
          label="版本"
          header-align="center"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="modifyrecord"
          label="修改记录"
          header-align="center"
          align="center"
          width="160">
      </el-table-column>
      <el-table-column
          prop="modifyreason"
          label="修改原因"
          header-align="center"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          prop="modifyperson"
          label="修改人"
          header-align="center"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="modifytime"
          label="修改时间"
          header-align="center"
          align="center"
          width="105">
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  name: "checkDataQualityMeasurePoint",
  data() {
    return {
      checkMPdialogVisible: false,
      checkMPtable: [{ // 添加数据项的表单数据
        name: '',
        isExisted: '',
        type: '',
        uprange: '',
        lowrange: '',
        unit: '',
        rationalityuprange: '',
        rationalitylowrange: '',
        storage_len: '',
        timedelay: '',
        timesequence: '',
        isExistedmethod: '',
        typemethod: '',
        rationalitymethod: '',
        storage_lenmethod: '',
        timedelaymethod: '',
        timesequencemethod: '',
        version: '',
        modifyrecord: '',
        modifyreason: '',
        modifyperson: '',
        modifytime: '',
      }],
      Methodoptions: [],
    }
  },
  mounted(){
    this.getMethodOptions();
  },
  methods: {
    init(row) {
      this.checkMPdialogVisible = true;
      this.getRequest('/dataQualityMeasurePoint/getMeasurePointAllVersionbyMeasurePointId/?MeasurePointId=' + row.mpId).then( resp => {
        if(resp.data) {
          this.checkMPtable = resp.data;
          this.checkMPtable.forEach(item=>{ //让治理函数从数字变成文字回显
            if (item.isExistedmethod !== null) {
              item.isExistedmethod = this.Methodoptions[item.isExistedmethod-1].name;
            }
            if (item.typemethod !== null) {
              item.typemethod = this.Methodoptions[item.typemethod-1].name;
            }
            if (item.rationalitymethod !== null) {
              item.rationalitymethod = this.Methodoptions[item.rationalitymethod-1].name;
            }
            if (item.storage_lenmethod !== null) {
              item.storage_lenmethod = this.Methodoptions[item.storage_lenmethod-1].name;
            }
            if (item.timedelaymethod !== null) {
              item.timedelaymethod = this.Methodoptions[item.timedelaymethod-1].name;
            }
            if (item.timesequencemethod !== null) {
              item.timesequencemethod = this.Methodoptions[item.timesequencemethod-1].name;
            }
          })
          console.log(this.checkMPtable)
        }
      })
    },

    getMethodOptions() {
      this.getRequest('/dataQualityMeasurePoint/getAllMethod').then( resp => {
        if(resp.data) {
          this.Methodoptions = resp.data;
        }
      })
    },
    closeDialog() {
      this.checkMPdialogVisible = false;
      this.$refs["checkMPtableRef"].resetFields();
    }
  }

}
</script>

<style scoped>

</style>
